import { Context } from '@nuxt/types';

export enum RedirectStatus {
  Initial = 'initial',
  NoRedirect = 'no-redirect',
  Redirect = 'redirect',
  Error = 'error',
}

export interface PagePathHandlerState {
  isDataValid: boolean;
  redirectStatus: RedirectStatus;
  error: Error | null;
}

export interface RedirectConditions {
  id: boolean;
  slug: boolean;
  channel: boolean;
}

export class PagePathHandler {
  protected _name: string;
  protected _error: Error | null = null;
  protected _isDataValid = true;
  protected _redirectStatus: RedirectStatus = RedirectStatus.Initial;
  protected _redirectConditions: RedirectConditions = {
    id: false,
    slug: false,
    channel: true,
  };

  constructor({ name, redirectConditions }: { name: string; redirectConditions: RedirectConditions }) {
    this._name = name;
    this._redirectConditions = redirectConditions;
  }

  /**
   * Check for conditions whether to stop redirect or continue with redirect
   * @param id - ID of the data
   * @param slug - SLUG of the data, optional because some path do not have a slug
   * @param sameChannel - Check if data channel and active channel match
   */
  protected shouldRedirect({ id, slug, sameChannel, route }: { id: number; slug?: string; route: Context['route']; sameChannel: boolean }): boolean {
    let shouldRedirect = false;

    // Redirect if CHANNEL redirection is enabled and CHANNEL is different
    if (this._redirectConditions.channel && !sameChannel) {
      shouldRedirect = true;
    }

    // Redirect if ID redirection is enabled and ID is different
    if (this._redirectConditions.id && !(route.params.id === String(id))) {
      shouldRedirect = true;
    }

    // Redirect if SLUG redirection is enabled and SLUG is different
    if (this._redirectConditions.slug && !(route.params.slug === slug)) {
      shouldRedirect = true;
    }

    if (!shouldRedirect) {
      this._redirectStatus = RedirectStatus.NoRedirect;
    }

    return shouldRedirect;
  }

  /**
   * Check if the query URL is valid
   * Check for conditions whether to stop redirect loop
   */
  protected isRedirectUrlValid(queryUrl: string | null, { domain, route }: { domain: string; route: Context['route'] }): queryUrl is string {
    if (!queryUrl) {
      this._redirectStatus = RedirectStatus.Error;
      this._error = new Error(`${this._name} createPath: Invalid queryUrl - route.fullPath: ${route.fullPath}`);
      return false;
    }

    const isIdIncludedInRoute = Number.isNaN(Number(route.params.id)) ? false : queryUrl.includes(route.params.id);

    const stopRedirectLoop = queryUrl.includes(domain) && isIdIncludedInRoute && queryUrl.includes(route.params.slug);

    if (stopRedirectLoop) {
      this._redirectStatus = RedirectStatus.Error;
      this._error = new Error(`${this._name} createPath: Prevent infinite loop - route.fullPath: ${route.fullPath}`);
      return false;
    }

    return true;
  }

  /**
   * Checks if the provided data is valid for redirection.
   */
  protected isRedirectDataValid<T extends { id: number }>(data?: T | null): data is T {
    if (!this._isDataValid) {
      this._redirectStatus = RedirectStatus.Error;
    }

    return this._isDataValid;
  }

  /**
   * Sets the redirect status to Redirect and returns the query URL
   */
  protected handleRedirectPath(queryUrl: string): string {
    this._redirectStatus = RedirectStatus.Redirect;
    return queryUrl;
  }

  /**
   * Get PagePath state for redirect handling
   */
  public getState<K extends keyof PagePathHandlerState>(key: K): PagePathHandlerState[K] {
    const state = {
      isDataValid: this._isDataValid,
      redirectStatus: this._redirectStatus,
      error: this._error,
    };

    return state[key];
  }
}
